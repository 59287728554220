import { all, delay, put, takeEvery } from 'redux-saga/effects'

import { services } from '../../graphql'
import ApiSagas from '../api/sagas'
import { actions } from '../index'

import { getUserByIdService } from './redux'

export default class ExampleSagas {
  static *onUpdateUserRequest({ payload }: ReturnType<typeof actions.example.updateUserRequest>) {
    // fake delay
    yield delay(500)

    const response = yield* ApiSagas.call(services.example.mutations.updateUser, {
      id: payload.id,
      name: payload.name,
    })

    if (response.errors) {
      yield put(actions.example.updateUserError(response.errors))
      return
    }

    if (response.data) {
      yield put(actions.example.updateUserSuccess(response.data))
    }
  }
  static *listeners() {
    yield all([
      // Example of generated saga usage
      getUserByIdService.getSagaEffect(ApiSagas.call, 'example'),
      // Example of custom saga usage
      takeEvery(actions.example.updateUserRequest, this.onUpdateUserRequest),
    ])
  }
}
