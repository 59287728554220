import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { UserFragmentFragment } from '../../graphql/generated/graphql'

import { actionTypes } from './types'

//
// Services
//

export const loginService = getCustomService<'login', null, { email: string; password: string }>(
  'login'
)

//
// Initial state
//

export type AuthState = {
  user: UserFragmentFragment | null
  token: string | null
  impersonate?: boolean
  // services
  login: typeof loginService.state
}

const initialState: AuthState = {
  user: null,
  token: null,
  impersonate: false,
  // services
  login: loginService.state,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUser: (state, { payload }: actionTypes.setUser) => {
      state.user = payload
    },
    setToken: (state, action: actionTypes.setToken) => {
      state.token = action.payload
    },
    setImpersonate: (state, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload
    },
    ...loginService.reducers,
  },
  extraReducers: {
    [REHYDRATE]: (state, action: PayloadAction<any>) => {
      const { user = null } = action.payload?.auth || {}

      return {
        ...initialState,
        user,
      }
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const user = (state: RootState) => root(state).user
const impersonate = (state: RootState) => root(state).impersonate
const token = (state: RootState) => root(state).token
const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})
const isConnected = createSelector([token], (t): boolean => {
  return !!t && t !== ''
})
const isExpired = createSelector([jwt], (j): boolean => {
  return !!j && !!j.exp && j.exp * 1000 <= new Date().getTime()
})
const login = (state: RootState) => root(state).login

export const selectors = {
  user,
  impersonate,
  token,
  jwt,
  isConnected,
  isExpired,
  login,
}
