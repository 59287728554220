import * as Yup from 'yup'
import { i18n } from 'i18next'

// CUSTOM LOCALES

export const setYupLocale = (_i18nInstance: i18n) => {
  Yup.setLocale({
    mixed: {
      required: _i18nInstance.t('form.error.required'),
      notType: ({ path, type, value, originalValue }) => {
        if (type === 'date') {
          return _i18nInstance.t('form.error.date')
        }

        if (type === 'number') {
          return _i18nInstance.t('form.error.number')
        }

        const castMsg =
          originalValue != null && originalValue !== value
            ? ` (cast from the value \`${originalValue}\`).`
            : '.'

        if (type !== 'mixed') {
          return (
            `${path} must be a \`${type}\` type, but the final value was: \`${value}\`` + castMsg
          )
        }

        return (
          `${path} must match the configured type. ` +
          `The validated value was: \`${value}\`` +
          castMsg
        )
      },
    },
    string: {
      email: _i18nInstance.t('form.error.email'),
    },
    array: {
      min: ({ min }) => _i18nInstance.t('form.error.array.min', { count: min }),
    },
    number: {
      min: ({ min }) => _i18nInstance.t('form.error.number.min', { min: min }),
    },
  })
}
