import { gql } from '../../../generated'
import { getQueryService } from '../../../../helpers/GraphqlHelpers'

export const login = getQueryService({
  query: gql(/* GraphQL */ `
    query AuthLogin {
      __typename
    }
  `),
  transformer: (response) => {
    return response
  },
})
