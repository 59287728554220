import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  padding: 2rem;
`

export const Title = styled.p`
  font-size: 25px;
`

export const Cta = styled(ActionButton)`
  margin-top: 3rem;
  width: auto;
`
