import { all, put, SagaReturnType, select, takeEvery, delay } from 'redux-saga/effects'

import { actions, selectors } from '..'
import { getAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie()
    console.log('AUTH : init with cookie', cookie)
    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
      yield put(actions.auth.setToken(cookie?.token))
    }

    const isConnected: SagaReturnType<typeof selectors.auth.isConnected> = yield select(
      selectors.auth.isConnected
    )
    console.log('AUTH : is connected', isConnected)
    // TODO: get user data
    // if (isConnected) {
    //   const rs  = yield* ApiSagas.call(
    //     services.user.queries.me
    //   )
    //   console.log('AUTH : get me', rs)
    //   if (!rs?.errors) {
    //     yield put(actions.auth.setUser(rs?.data))
    //   } else {
    //     yield put(actions.auth.resetAuth())
    //   }
    // }
  }

  static *onLoginRequest({ payload }: ReturnType<typeof actions.auth.loginRequest>) {
    // const response = yield* ApiSagas.call(
    //   services.auth.queries.login,
    //   {
    //     email: payload.email,
    //     password: payload.password,
    //   }
    // )

    // fake delay
    yield delay(1000)

    // fake API response
    const isGood = payload.email === 'test@local.dev' && payload.password === 'bbs'
    if (!isGood) {
      yield put(actions.auth.loginError([]))
      return
    }

    const response = {
      data: isGood,
      errors: !isGood ? [] : null,
    }

    if (response.data) {
      setAuthCookie({ token: 'mock_token', impersonate: false })
      yield put(actions.auth.setToken('mock_token'))
      yield put(actions.auth.loginSuccess())
    }
  }

  static *listeners() {
    yield all([takeEvery(actions.auth.loginRequest, this.onLoginRequest)])
  }
}
